@import 'styles/Theme.scss';

.main {
  padding-top: $DESKTOPHEAD;
  padding-bottom: 8px;
  width: calc(100% - 32px);
  overflow-x: hidden;
  @include paddingHorizontal(16);
  @include for-size(mobile-portrait) {
    padding: 0;
    width: 100%;
    padding-top: $MOBILEHEAD;
  }
  @include for-size(mobile-landscape) {
    padding: 0;
    width: 100%;
    padding-top: $MOBILEHEAD;
  }
  @include for-size(tablet-portrait) {
    padding: 0;
    width: 100%;
    padding-top: $MOBILEHEAD;
  }
  &.debug {
    @include for-size(mobile-portrait) {
      background-color: red;
    }
    @include for-size(mobile-landscape) {
      background-color: yellow;
    }
    @include for-size(tablet-portrait) {
      background-color: pink;
    }
    @include for-size(tablet-landscape) {
      background-color: cyan;
    }
  }
}
