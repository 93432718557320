@import 'styles/Theme.scss';

.container {
  z-index: 101;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 80%;
  max-width: 32rem;
  @include column();
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: $NEUTRAL;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.3);
  p {
    line-height: 1.5;
    text-align: justify;
    margin-bottom: 1rem;
    @include for-size(mobile-landscape) {
      line-height: 1.1;
      margin-bottom: 0rem;
    }
    @include for-size(mobile-portrait) {
      line-height: 1.1;
      margin-bottom: 0rem;
    }
    a {
      color: $SECONDARY;
      font-weight: 500;
    }
  }
  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @include for-size(mobile-portrait) {
      flex-direction: column;
    }
    @include for-size(mobile-landscape) {
      flex-direction: column;
    }
    label {
      @include paddingVertical(6);
    }
    button {
      flex: 1;
      text-align: center;
      white-space: nowrap;
      @include paddingHorizontal(16);
      @include paddingVertical(12);
      @include bounceTop();
      border: none;
      background-color: $PRIMARY;
      color: $WHITE;
      font-weight: 400;
      font-size: 14px;
      @include for-size(mobile-portrait) {
        @include marginVertical(5);
      }
      @include for-size(mobile-landscape) {
        @include marginVertical(5);
      }
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
      &:not(:last-child) {
        @include for-size(mobile-landscape) {
          margin-right: 0px;
        }
        @include for-size(mobile-portrait) {
          margin-right: 0px;
        }
        margin-right: 10px;
      }
    }
  }
  .checkBoxcontainer {
    @include paddingHorizontal(16);
    @include paddingVertical(12);
    width: 100%;
    .checkBoxCookie {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      label {
        width: 100%;
        box-sizing: border-box;
        padding-right: 10px;
        padding-bottom: 10px;
        @include for-size(mobile-landscape) {
          width: 100%;
          font-size: 14px;
        }
        @include for-size(mobile-portrait) {
          width: 100%;
          font-size: 14px;
        }
      }
      width: auto;
      display: flex;
      flex-direction: row;
      border: 1px solid #cccccc;
      -webkit-border-radius: 4px 0 0 4px;
      -moz-border-radius: 4px 0 0 4px;
      border-radius: 4px 0 0 4px;
      justify-content: space-around;
      padding: 4px 3px 3px 9px;
      text-align: left;
      vertical-align: top;
      label {
        display: flex;
        align-items: center;
      }

      input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid $SECONDARY;
        color: $SECONDARY;
        margin-left: 0;
        background-color: $WHITE;
        margin-right: 0.5rem;
        &:focus {
          outline: none;
        }
        &:checked + span {
          color: $BLACK;
          font-weight: 500;
        }
      }

      input[type='checkbox']::before {
        content: '✔';
        position: absolute;
        visibility: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input[type='checkbox']:checked::before {
        visibility: visible;
        background-color: $NEUTRAL;
      }
    }
    .checkboxWith {
      @include for-size(mobile-landscape) {
        width: 50%;
      }
      @include for-size(mobile-portrait) {
        width: 50%;
      }
    }
  }
}
