@import 'styles/Theme.scss';

.container {
  @include row();
  align-items: center;
  .language {
    @include caption();
    @include marginHorizontal(8);
    @include paddingVertical(4);
    border-bottom: 3px solid $TRANSPARENT;
    background-color: $TRANSPARENT;
    color: $GRAY200;
    text-transform: uppercase;
    @include for-size(mobile-portrait) {
      @include strong();
      font-weight: 800;
    }
    @include for-size(mobile-landscape) {
      @include strong();
      font-weight: 800;
    }
    @include for-size(tablet-portrait) {
      @include strong();
      font-weight: 800;
    }
    &.selected {
      border-bottom-color: $BLACK;
      color: $BLACK;
    }
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  }
}
