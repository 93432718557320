@import 'styles/Theme.scss';

.container {
  background-color: $PRIMARY;
  .wrapper {
    max-width: 1400px;
    margin: 0 auto;
    @include grid();
    @include sectionPaddings();
    padding-bottom: 1rem !important;
    grid-template-columns: 0.5fr 1fr 0.9fr 1fr;
    grid-template-rows: 1fr auto;
    gap: 2rem 0px;
    grid-template-areas:
      'a b c d'
      'low low low low';
    @include for-size(mobile-portrait) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'a'
        'b'
        'c'
        'd'
        'low';
    }
    @include for-size(mobile-landscape) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'a a'
        'b b'
        'c d'
        'low low';
    }
    @include for-size(tablet-portrait) {
    }
    @include for-size(tablet-landscape) {
    }
    @include for-size(desktop) {
    }
    .a {
      grid-area: a;
      padding-top: 8px;
      width: 48px;

      svg {
        @include for-size(mobile-portrait) {
          width: 40px;
        }
        @include for-size(mobile-landscape) {
        }
        @include for-size(tablet-portrait) {
        }
        @include for-size(tablet-landscape) {
        }
        @include for-size(desktop) {
        }
      }
    }
    .b {
      grid-area: b;
      @include column();
      justify-content: space-between;
      .title {
        color: $WHITE;
        font-weight: 800;
        font-size: 1.3rem;
      }
      .quote {
        color: $WHITE;
        line-height: 24px;
        padding-right: calc(min(15%, 6rem));
      }
      .socialContainer {
        padding-top: 2rem;
        margin-bottom: 32px;
        color: $WHITE;
        @include row();
        align-items: flex-end;
        svg {
          margin-right: 2rem;
          height: 22px;
          fill: $WHITE;
        }
      }
    }
    .c {
      @include for-size(desktop) {
        padding-left: 2rem;
      }

      grid-area: c;
      color: $WHITE;

      ul {
        @include column();
        li {
          @include paddingVertical(4);
          a {
            border-bottom: 2px solid $TRANSPARENT;
            font-size: 1.225rem;
            &:hover {
              color: $SECONDARY;
              border-bottom-color: $SECONDARY;
            }
            @include for-size(mobile-portrait) {
              justify-content: center;
            }
            @include for-size(mobile-landscape) {
              justify-content: center;
            }
          }
        }
      }
    }
    .d {
      grid-area: d;
      .place {
        @include column();
        align-items: flex-start;
        color: $WHITE;
        margin-bottom: 32px;
        svg {
          margin-bottom: 0.5rem;
          width: 32px;
        }
        .llegar {
          @include row();
          align-items: center;
          color: $SECONDARY;
          @include paddingVertical(8);
          font-weight: 500;
        }
        .city {
          @include strong();
          font-weight: 800;
          font-size: 1.3rem;
          display: none;
        }
        .phone {
        }
        .headquarters {
          @include strong();
          font-size: 1.2rem;
        }
      }
    }
    .low {
      @include row();
      @include small();
      justify-content: center;
      color: $WHITE;
      grid-area: low;
      @include for-size(tablet-landscape) {
        padding-top: 64px;
      }
      @include for-size(desktop) {
        padding-top: 64px;
      }
      span {
        @include marginHorizontal(16);
      }
      a {
        border-bottom: 2px solid $TRANSPARENT;
        &:hover {
          color: $SECONDARY;
          border-bottom-color: $SECONDARY;
        }
      }
      p {
        margin-right: 1rem;
      }
      @include for-size(mobile-portrait) {
        @include column();
        flex-wrap: wrap;
        align-items: flex-start;
        span {
          display: none;
        }
      }
      @include for-size(mobile-landscape) {
        flex-wrap: wrap;
      }
    }
  }
}
