@import 'styles/Theme.scss';

.container {
  position: fixed;
  transition: top 0.5s cubic-bezier(0.5, 1, 0.89, 1);
  width: 100%;
  z-index: 100;
  .navigationHead {
    z-index: 51;
    height: $DESKTOPHEAD;
    @include row();
    align-items: center;
    justify-content: space-between;
    background-color: $WHITE;
    @include paddingHorizontal(16);
    @include for-size(mobile-portrait) {
      height: $MOBILEHEAD;
    }
    @include for-size(mobile-landscape) {
      height: $MOBILEHEAD;
    }
    @include for-size(tablet-portrait) {
      height: $MOBILEHEAD;
    }
    .homeLink {
      flex: 1;
      width: 100%;
      max-width: calc(max(240px, 20%));
      @include row();
      align-items: center;

      position: relative;
      @include paddingVertical(4);

      &:focus {
        outline: none;
      }
      &:hover {
        .logo {
          animation: fadeOut 1s linear 0s forwards;
          @keyframes fadeOut {
            0% {
              opacity: 1;
              transform: rotate(360deg);
            }
            20% {
              opacity: 1;
            }
            100% {
              opacity: 1;
              transform: rotate(0deg);
            }
          }
        }
        .title {
          //animation: fadeIn 0.2s linear 0s forwards;
          @keyframes fadeIn {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
        }
      }

      svg {
        height: 24px;
        margin-right: 8px;
      }
      .logo {
        opacity: 1;
        transition: opacity 1s smooth;
        @include for-size(mobile-portrait) {
          position: static;
          opacity: 1;
        }
        @include for-size(mobile-landscape) {
          position: static;
          opacity: 1;
        }
        @include for-size(tablet-portrait) {
          position: static;
          opacity: 1;
        }
      }
      .title {
        @include for-size(mobile-portrait) {
          position: static;
        }
        @include for-size(mobile-landscape) {
          position: static;
        }
        @include for-size(tablet-portrait) {
          position: static;
        }

        left: 0;
        right: 0;
      }
    }

    .links {
      flex: 1;
      @include row();
      justify-content: flex-end;

      @include for-size(mobile-portrait) {
        display: none;
      }
      @include for-size(mobile-landscape) {
        display: none;
      }
      @include for-size(tablet-portrait) {
        display: none;
      }
      li {
        @include strong();
        font-weight: 500;
        a {
          @include paddingVertical(16);
          @include paddingHorizontal(24);
          color: $BLACK;
          border-bottom: 2px solid $TRANSPARENT;
          &:hover {
            color: $SECONDARY;
            border-bottom-color: $SECONDARY;
          }
          &:focus {
            outline: none;
          }
          svg {
            display: none;
          }
        }
      }
    }
    .languageDropdownContainer {
      @include for-size(mobile-portrait) {
        display: none;
      }
      @include for-size(mobile-landscape) {
        display: none;
      }
      @include for-size(tablet-portrait) {
        display: none;
      }
    }
    .mobileButton {
      background-color: $TRANSPARENT;
      &:focus {
        outline: none;
      }
      @include for-size(tablet-landscape) {
        display: none;
      }
      @include for-size(desktop) {
        display: none;
      }
      justify-self: flex-end;
    }
  }

  .mobileDropdown {
    position: absolute;
    top: calc(min(-100vh, -720px));
    z-index: 50;
    width: 100%;
    height: calc(100vh - #{$MOBILEHEAD});
    background-color: $NEUTRAL;
    padding-top: calc(min(10%, 32px));
    overflow-y: scroll;
    @include for-size(tablet-landscape) {
      display: none;
    }
    @include for-size(desktop) {
      display: none;
    }
    transition: top 800ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 5ms;
    &.show {
      top: $MOBILEHEAD;
      z-index: 0;
      transition: top 800ms cubic-bezier(0.37, 0, 0.63, 1) 0s;
    }
    .links {
      @include column();
      align-items: center;
      li {
        @include row();
        a {
          flex: 1;
          @include row();
          @include paddingHorizontal(48);
          padding-top: calc(max(5%, 8px));
          padding-bottom: calc(max(5%, 8px));

          font-family: $REGULARFONT;
          font-size: 1.35rem;
          font-weight: 500;
          color: $BLACK;
          border-bottom: 3px solid $TRANSPARENT;
          &:focus {
            outline: none;
          }
          &:hover {
            color: $SECONDARY;
            border-bottom-color: $SECONDARY;
          }
          span {
            flex: 1;
          }
          svg {
            display: none;
            height: 24px;
            width: 24px;
          }
        }
      }
    }
    .smileLogo {
      @include row();
      padding-top: 1rem;
      justify-content: center;
      svg {
        width: 32px;
      }
    }
    .languageDropdownContainer {
      @include row();
      justify-content: center;
      align-items: center;
      margin-top: 32px;
      min-height: 64px;
    }
  }
}
